import React, { useEffect, useState } from 'react';

import { button, loading } from './protected-file.module.scss';
import { getUserTokenData } from '../../utills/get-user-token-data';

interface IProtectedFileProps {
    className?: string;
    url: string;
    mimeType?: string;
    children?: React.ReactNode;
    isDownload?: boolean;
}

const ProtectedFile: React.FC<IProtectedFileProps> = ({
    className = '',
    url,
    children,
    mimeType,
    isDownload = false,
}) => {
    const [objectUrl, setObjectUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const tokenData = getUserTokenData();
    const token = tokenData?.token;

    const handleClick = async (event: React.MouseEvent) => {
        if (!token) return;
        setIsLoading(true);
        const fileObjectUrl = await getProtectedFile(token, url, mimeType);
        setIsLoading(false);
        setObjectUrl(fileObjectUrl);
        const a = document.createElement('a');
        a.href = fileObjectUrl;
        a.target = 'blank';
        if (isDownload) {
            a.download = '';
        }
        a.click();
    };

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(objectUrl);
        };
    }, [objectUrl]);

    return (
        <button
            className={`${button} ${className} ${isLoading ? loading : ''}`}
            onClick={handleClick}
            disabled={isLoading}
        >
            {children}
        </button>
    );
};

async function getProtectedFile(token: string, url: string, mimeType?: string) {
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${token}`);
    const response = await fetch(url, { headers });
    const responseBlob = await response.blob();
    const blob = new Blob([responseBlob], { type: mimeType });
    return URL.createObjectURL(blob);
}

export default ProtectedFile;
