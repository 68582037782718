// extracted by mini-css-extract-plugin
export var actionButtons = "shopping-list-module--action-buttons--f3Ufw";
export var actionCount = "shopping-list-module--action-count--Dfzl9";
export var actionRow = "shopping-list-module--action-row--CNRXn";
export var container = "shopping-list-module--container--AU8No";
export var deleteButton = "shopping-list-module--delete-button--2VTWa";
export var deleteButtonText = "shopping-list-module--delete-button-text--BDJJn";
export var dot = "shopping-list-module--dot--O3EyT";
export var editButton = "shopping-list-module--editButton--dPMJM";
export var item = "shopping-list-module--item--AzFn5";
export var list = "shopping-list-module--list--1ZWBb";
export var listIcon = "shopping-list-module--list-icon--uG0gi";
export var loading = "shopping-list-module--loading--QtDvs";
export var name = "shopping-list-module--name--P95jB";
export var nameBox = "shopping-list-module--name-box--Vj-U1";
export var pdfButton = "shopping-list-module--pdfButton--kXMaf";
export var pdfProtectedLink = "shopping-list-module--pdf-protected-link--dlHZ0";