import { config } from '../../config';
import {
    getTransitionDailyReports,
    getTransitionWeeklyReports,
    restoreTransitionDailyReportsInitialState,
    restoreTransitionInitialState,
    restoreTransitionReportsHistoryInitialState,
    restoreTransitionScoresInitialState,
    restoreTransitionWeeklyReportInitialState,
    restoreTransitionWeeklyReportsInitialState,
    setTransitionDailyReports,
    setTransitionReportsHistory,
    setTransitionScores,
    setTransitionWeeklyReport,
    setTransitionWeeklyReports,
    TRANSITION_DAILY_REPORT,
    TRANSITION_DAILY_REPORTS,
    TRANSITION_REPORTS_HISTORY,
    TRANSITION_REPORTS_HISTORY_PER_PAGE,
    TRANSITION_SCORES,
    TRANSITION_WEEKLY_REPORT,
    TRANSITION_WEEKLY_REPORTS,
    TRANSITION_MEASUREMENTS,
    types,
    setTransitionMeasurements,
    restoreTransitionMeasurementsInitialState,
    TRANSITION_MEASUREMENT_DEFAULT,
    setTransitionMeasurementDefault,
    restoreTransitionMeasurementDefaultInitialState,
    TRANSITION_MEASUREMENT,
    getTransitionMeasurements,
    TRANSITION_GALLERY,
    setTransitionGallery,
    restoreTransitionGalleryInitialState,
    getTransitionGallery,
    setTransitionDailyReport,
    restoreTransitionDailyReportInitialState,
    getTransitionDailyReport,
    getTransitionScores,
    TRANSITION_MEASUREMENT_COUNT,
    restoreTransitionMeasurementCountInitialState,
    setTransitionMeasurementCount,
    getTransitionMeasurementCount,
} from './transition.actions';
import { getCurrentProfile } from '../profile/profile.selectors';
import { errorHandler, formatFormikErrors } from '../../communication/utils';
import { setAlert } from '../alerts/alerts.actions';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';
import { statusIdle } from '../ui/ui.actions';
import { removeModalAction } from '../actions/actions-modals';
import {
    selectTransitionDailyReport,
    selectTransitionDailyReports,
    selectTransitionWeeklyReports,
} from './transition.selectors';
import { getParamsFromFilters } from '../../utills/filter-utils';

const {
    GET_TRANSITION_DAILY_REPORTS,
    UPDATE_TRANSITION_DAILY_REPORT,
    DELETE_TRANSITION_DAILY_REPORT,
    CREATE_TRANSITION_DAILY_REPORT,
    CLEAR_TRANSITION,
    CLEAR_TRANSITION_DAILY_REPORTS,
    SEND_TRANSITION_DAILY_REPORTS_REQUEST,
    SEND_TRANSITION_DAILY_REPORTS_SUCCESS,
    SEND_TRANSITION_DAILY_REPORTS_FAIL,
    DELETE_TRANSITION_DAILY_REPORT_REQUEST,
    DELETE_TRANSITION_DAILY_REPORT_SUCCESS,
    DELETE_TRANSITION_DAILY_REPORT_FAIL,
    POST_TRANSITION_DAILY_REPORT_REQUEST,
    POST_TRANSITION_DAILY_REPORT_SUCCESS,
    POST_TRANSITION_DAILY_REPORT_FAIL,
    PATCH_TRANSITION_DAILY_REPORT_REQUEST,
    PATCH_TRANSITION_DAILY_REPORT_SUCCESS,
    PATCH_TRANSITION_DAILY_REPORT_FAIL,
    GET_TRANSITION_WEEKLY_REPORTS,
    SEND_TRANSITION_WEEKLY_REPORTS_REQUEST,
    SEND_TRANSITION_WEEKLY_REPORTS_SUCCESS,
    SEND_TRANSITION_WEEKLY_REPORTS_FAIL,
    CREATE_TRANSITION_WEEKLY_REPORT,
    POST_TRANSITION_WEEKLY_REPORT_REQUEST,
    POST_TRANSITION_WEEKLY_REPORT_SUCCESS,
    POST_TRANSITION_WEEKLY_REPORT_FAIL,
    UPDATE_TRANSITION_WEEKLY_REPORT,
    PATCH_TRANSITION_WEEKLY_REPORT_REQUEST,
    PATCH_TRANSITION_WEEKLY_REPORT_SUCCESS,
    PATCH_TRANSITION_WEEKLY_REPORT_FAIL,
    SUBMIT_TRANSITION_WEEKLY_REPORTS,
    SUBMIT_TRANSITION_WEEKLY_REPORTS_REQUEST,
    SUBMIT_TRANSITION_WEEKLY_REPORTS_SUCCESS,
    SUBMIT_TRANSITION_WEEKLY_REPORTS_FAIL,
    CLEAR_TRANSITION_WEEKLY_REPORTS,
    GET_TRANSITION_REPORTS_HISTORY,
    SEND_TRANSITION_REPORTS_HISTORY_REQUEST,
    SEND_TRANSITION_REPORTS_HISTORY_SUCCESS,
    SEND_TRANSITION_REPORTS_HISTORY_FAIL,
    CLEAR_TRANSITION_REPORTS_HISTORY,
    DELETE_TRANSITION_WEEKLY_REPORT,
    DELETE_TRANSITION_WEEKLY_REPORT_REQUEST,
    DELETE_TRANSITION_WEEKLY_REPORT_SUCCESS,
    DELETE_TRANSITION_WEEKLY_REPORT_FAIL,
    GET_TRANSITION_WEEKLY_REPORT,
    SEND_TRANSITION_WEEKLY_REPORT_REQUEST,
    SEND_TRANSITION_WEEKLY_REPORT_SUCCESS,
    SEND_TRANSITION_WEEKLY_REPORT_FAIL,
    CLEAR_TRANSITION_WEEKLY_REPORT,
    GET_TRANSITION_SCORES,
    CLEAR_TRANSITION_SCORES,
    SEND_TRANSITION_SCORES_REQUEST,
    SEND_TRANSITION_SCORES_SUCCESS,
    SEND_TRANSITION_SCORES_FAIL,
    GET_TRANSITION_MEASUREMENTS,
    SEND_TRANSITION_MEASUREMENTS_REQUEST,
    SEND_TRANSITION_MEASUREMENTS_SUCCESS,
    SEND_TRANSITION_MEASUREMENTS_FAIL,
    CLEAR_TRANSITION_MEASUREMENTS,
    GET_TRANSITION_MEASUREMENT_DEFAULT,
    SEND_TRANSITION_MEASUREMENT_DEFAULT_REQUEST,
    SEND_TRANSITION_MEASUREMENT_DEFAULT_SUCCESS,
    SEND_TRANSITION_MEASUREMENT_DEFAULT_FAIL,
    CLEAR_TRANSITION_MEASUREMENT_DEFAULT,
    CREATE_TRANSITION_MEASUREMENT,
    POST_TRANSITION_MEASUREMENT_REQUEST,
    POST_TRANSITION_MEASUREMENT_SUCCESS,
    POST_TRANSITION_MEASUREMENT_FAIL,
    GET_TRANSITION_GALLERY,
    SEND_TRANSITION_GALLERY_REQUEST,
    SEND_TRANSITION_GALLERY_SUCCESS,
    SEND_TRANSITION_GALLERY_FAIL,
    CLEAR_TRANSITION_GALLERY,
    GET_TRANSITION_DAILY_REPORT,
    SEND_TRANSITION_DAILY_REPORT_REQUEST,
    SEND_TRANSITION_DAILY_REPORT_SUCCESS,
    SEND_TRANSITION_DAILY_REPORT_FAIL,
    CLEAR_TRANSITION_DAILY_REPORT,
    UPDATE_TRANSITION_MEASUREMENT,
    PATCH_TRANSITION_MEASUREMENT_REQUEST,
    PATCH_TRANSITION_MEASUREMENT_SUCCESS,
    PATCH_TRANSITION_MEASUREMENT_FAIL,
    GET_TRANSITION_MEASUREMENT_COUNT,
    SEND_TRANSITION_MEASUREMENT_COUNT_REQUEST,
    SEND_TRANSITION_MEASUREMENT_COUNT_SUCCESS,
    SEND_TRANSITION_MEASUREMENT_COUNT_FAIL,
    CLEAR_TRANSITION_MEASUREMENT_COUNT,
} = types;

const { formsStatusMap } = config;

const transitionMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const profile = getCurrentProfile(getState());
    const dailyReports = selectTransitionDailyReports(getState());
    const dailyReport = selectTransitionDailyReport(getState());
    const weeklyReports = selectTransitionWeeklyReports(getState());
    const profileId = profile?.profileId;
    const { type, payload, meta } = action;
    const error = (action.error && errorHandler(action.error)) || [];

    const refetchDailyReports = () => {
        if (dailyReports.length) {
            const from = getState().transition.dailyReports.from;
            const to = getState().transition.dailyReports.to;
            dispatch(getTransitionDailyReports({ from, to }));
        }
    };

    const refetchDailyReport = () => {
        if (dailyReport) {
            dispatch(getTransitionDailyReport(dailyReport.date));
        }
    };

    const refetchWeeklyReports = () => {
        if (weeklyReports.length) {
            dispatch(getTransitionWeeklyReports());
        }
    };

    const refetchScores = () => {
        dispatch(getTransitionScores(getParamsFromFilters(getState().transition.scores.filters)));
    };

    const refetchMeasurements = () => {
        dispatch(
            getTransitionMeasurements({
                ...getParamsFromFilters(getState().transition.measurements.filters),
            })
        );
    };

    const refetchGallery = () => {
        dispatch(
            getTransitionGallery({
                ...getParamsFromFilters(getState().transition.gallery.filters),
            })
        );
    };

    const refetchMeasurementCount = () => {
        dispatch(getTransitionMeasurementCount());
    };

    next(action);

    switch (type) {
        case GET_TRANSITION_DAILY_REPORTS:
            dispatch({
                types: [
                    SEND_TRANSITION_DAILY_REPORTS_REQUEST,
                    SEND_TRANSITION_DAILY_REPORTS_SUCCESS,
                    SEND_TRANSITION_DAILY_REPORTS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.dailyReports(
                            profileId,
                            payload.from,
                            payload.to
                        ),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_DAILY_REPORTS,
                },
            });
            break;

        case SEND_TRANSITION_DAILY_REPORTS_SUCCESS:
            dispatch(setTransitionDailyReports(payload.data));
            break;

        case GET_TRANSITION_DAILY_REPORT:
            dispatch({
                types: [
                    SEND_TRANSITION_DAILY_REPORT_REQUEST,
                    SEND_TRANSITION_DAILY_REPORT_SUCCESS,
                    SEND_TRANSITION_DAILY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.dailyReports(profileId, payload, payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_DAILY_REPORT,
                },
            });
            break;

        case SEND_TRANSITION_DAILY_REPORT_SUCCESS:
            dispatch(setTransitionDailyReport(payload.data));
            break;

        case CREATE_TRANSITION_DAILY_REPORT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    POST_TRANSITION_DAILY_REPORT_REQUEST,
                    POST_TRANSITION_DAILY_REPORT_SUCCESS,
                    POST_TRANSITION_DAILY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'put',
                        url: config.endpoints.transition.dailyReport(profileId, payload.date),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_DAILY_REPORT },
            });
            break;

        case POST_TRANSITION_DAILY_REPORT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            refetchDailyReports();
            refetchDailyReport();
            refetchScores();
            refetchWeeklyReports();
            break;

        case POST_TRANSITION_DAILY_REPORT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case UPDATE_TRANSITION_DAILY_REPORT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    PATCH_TRANSITION_DAILY_REPORT_REQUEST,
                    PATCH_TRANSITION_DAILY_REPORT_SUCCESS,
                    PATCH_TRANSITION_DAILY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'patch',
                        url: config.endpoints.transition.dailyReport(profileId, payload.date),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_DAILY_REPORT },
            });
            break;

        case PATCH_TRANSITION_DAILY_REPORT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            refetchDailyReports();
            refetchDailyReport();
            refetchScores();
            refetchWeeklyReports();
            break;

        case PATCH_TRANSITION_DAILY_REPORT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case DELETE_TRANSITION_DAILY_REPORT:
            dispatch({
                types: [
                    DELETE_TRANSITION_DAILY_REPORT_REQUEST,
                    DELETE_TRANSITION_DAILY_REPORT_SUCCESS,
                    DELETE_TRANSITION_DAILY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'delete',
                        url: config.endpoints.transition.dailyReport(profileId, payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_DAILY_REPORT },
            });
            break;

        case DELETE_TRANSITION_DAILY_REPORT_SUCCESS:
            refetchDailyReports();
            refetchDailyReport();
            refetchScores();
            refetchWeeklyReports();
            break;

        case DELETE_TRANSITION_DAILY_REPORT_FAIL:
            error.map((err) => dispatch(setAlert(err, TRANSITION_DAILY_REPORT)));
            break;

        case CLEAR_TRANSITION_DAILY_REPORTS:
            dispatch(statusIdle(TRANSITION_DAILY_REPORTS));
            dispatch(restoreTransitionDailyReportsInitialState());
            break;

        case CLEAR_TRANSITION_DAILY_REPORT:
            dispatch(statusIdle(TRANSITION_DAILY_REPORT));
            dispatch(restoreTransitionDailyReportInitialState());
            break;

        case GET_TRANSITION_WEEKLY_REPORTS:
            dispatch({
                types: [
                    SEND_TRANSITION_WEEKLY_REPORTS_REQUEST,
                    SEND_TRANSITION_WEEKLY_REPORTS_SUCCESS,
                    SEND_TRANSITION_WEEKLY_REPORTS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.weeklyReports(profileId),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_WEEKLY_REPORTS,
                },
            });
            break;

        case SEND_TRANSITION_WEEKLY_REPORTS_SUCCESS:
            dispatch(setTransitionWeeklyReports(payload.data));
            break;

        case SUBMIT_TRANSITION_WEEKLY_REPORTS:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    SUBMIT_TRANSITION_WEEKLY_REPORTS_REQUEST,
                    SUBMIT_TRANSITION_WEEKLY_REPORTS_SUCCESS,
                    SUBMIT_TRANSITION_WEEKLY_REPORTS_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.transition.weeklyReportsSubmit(profileId),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_WEEKLY_REPORTS },
            });
            break;

        case SUBMIT_TRANSITION_WEEKLY_REPORTS_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            dispatch(
                setAlert(
                    {
                        type: 'success',
                        content:
                            'Brawo! Twój raport tygodniowy został wysłany. Sprawdzimy go jak najszybciej i wrócimy z informacją zwrotną.☺️!',
                    },
                    TRANSITION_WEEKLY_REPORTS
                )
            );
            refetchWeeklyReports();
            break;

        case SUBMIT_TRANSITION_WEEKLY_REPORTS_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case GET_TRANSITION_WEEKLY_REPORT:
            dispatch({
                types: [
                    SEND_TRANSITION_WEEKLY_REPORT_REQUEST,
                    SEND_TRANSITION_WEEKLY_REPORT_SUCCESS,
                    SEND_TRANSITION_WEEKLY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.weeklyReport(profileId, payload),
                        params: {
                            expand: `dailyReports,questions`,
                        },
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_WEEKLY_REPORT,
                },
            });
            break;

        case SEND_TRANSITION_WEEKLY_REPORT_SUCCESS:
            dispatch(setTransitionWeeklyReport(payload.data));
            break;

        case CREATE_TRANSITION_WEEKLY_REPORT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    POST_TRANSITION_WEEKLY_REPORT_REQUEST,
                    POST_TRANSITION_WEEKLY_REPORT_SUCCESS,
                    POST_TRANSITION_WEEKLY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.transition.weeklyReportCreate(profileId),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_WEEKLY_REPORT },
            });
            break;

        case POST_TRANSITION_WEEKLY_REPORT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            refetchWeeklyReports();
            break;

        case POST_TRANSITION_WEEKLY_REPORT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case UPDATE_TRANSITION_WEEKLY_REPORT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    PATCH_TRANSITION_WEEKLY_REPORT_REQUEST,
                    PATCH_TRANSITION_WEEKLY_REPORT_SUCCESS,
                    PATCH_TRANSITION_WEEKLY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'patch',
                        url: config.endpoints.transition.weeklyReport(profileId, payload.weekId),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_WEEKLY_REPORT },
            });
            break;

        case PATCH_TRANSITION_WEEKLY_REPORT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            refetchWeeklyReports();
            break;

        case PATCH_TRANSITION_WEEKLY_REPORT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case DELETE_TRANSITION_WEEKLY_REPORT:
            dispatch({
                types: [
                    DELETE_TRANSITION_WEEKLY_REPORT_REQUEST,
                    DELETE_TRANSITION_WEEKLY_REPORT_SUCCESS,
                    DELETE_TRANSITION_WEEKLY_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'delete',
                        url: config.endpoints.transition.weeklyReport(profileId, payload),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_WEEKLY_REPORT },
            });
            break;

        case DELETE_TRANSITION_WEEKLY_REPORT_SUCCESS:
            refetchWeeklyReports();
            break;

        case DELETE_TRANSITION_WEEKLY_REPORT_FAIL:
            error.map((err) => dispatch(setAlert(err, TRANSITION_WEEKLY_REPORT)));
            break;

        case CLEAR_TRANSITION_WEEKLY_REPORTS:
            dispatch(statusIdle(TRANSITION_WEEKLY_REPORTS));
            dispatch(restoreTransitionWeeklyReportsInitialState());
            break;

        case CLEAR_TRANSITION_WEEKLY_REPORT:
            dispatch(statusIdle(TRANSITION_WEEKLY_REPORT));
            dispatch(restoreTransitionWeeklyReportInitialState());
            break;

        case GET_TRANSITION_REPORTS_HISTORY:
            dispatch({
                types: [
                    SEND_TRANSITION_REPORTS_HISTORY_REQUEST,
                    SEND_TRANSITION_REPORTS_HISTORY_SUCCESS,
                    SEND_TRANSITION_REPORTS_HISTORY_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.reportsHistory(profileId),
                        params: {
                            perPage: TRANSITION_REPORTS_HISTORY_PER_PAGE,
                            page: payload.page,
                            ...payload.params,
                        },
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_REPORTS_HISTORY,
                },
            });
            break;

        case SEND_TRANSITION_REPORTS_HISTORY_SUCCESS:
            dispatch(setTransitionReportsHistory(payload.data));
            break;

        case CLEAR_TRANSITION_REPORTS_HISTORY:
            dispatch(statusIdle(TRANSITION_REPORTS_HISTORY));
            dispatch(restoreTransitionReportsHistoryInitialState());
            break;

        case GET_TRANSITION_SCORES:
            dispatch({
                types: [
                    SEND_TRANSITION_SCORES_REQUEST,
                    SEND_TRANSITION_SCORES_SUCCESS,
                    SEND_TRANSITION_SCORES_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.scores(profileId),
                        params: {
                            ...payload,
                        },
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_SCORES,
                },
            });
            break;

        case SEND_TRANSITION_SCORES_SUCCESS:
            dispatch(setTransitionScores(payload.data));
            break;

        case CLEAR_TRANSITION_SCORES:
            dispatch(statusIdle(TRANSITION_SCORES));
            dispatch(restoreTransitionScoresInitialState());
            break;

        case GET_TRANSITION_MEASUREMENTS:
            dispatch({
                types: [
                    SEND_TRANSITION_MEASUREMENTS_REQUEST,
                    SEND_TRANSITION_MEASUREMENTS_SUCCESS,
                    SEND_TRANSITION_MEASUREMENTS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.measurements(profileId),
                        params: {
                            ...payload,
                        },
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_MEASUREMENTS,
                },
            });
            break;

        case SEND_TRANSITION_MEASUREMENTS_SUCCESS:
            dispatch(setTransitionMeasurements(payload.data));
            break;

        case CLEAR_TRANSITION_MEASUREMENTS:
            dispatch(statusIdle(TRANSITION_MEASUREMENTS));
            dispatch(restoreTransitionMeasurementsInitialState());
            break;

        case GET_TRANSITION_MEASUREMENT_DEFAULT:
            dispatch({
                types: [
                    SEND_TRANSITION_MEASUREMENT_DEFAULT_REQUEST,
                    SEND_TRANSITION_MEASUREMENT_DEFAULT_SUCCESS,
                    SEND_TRANSITION_MEASUREMENT_DEFAULT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.measurementDefault(profileId),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_MEASUREMENT_DEFAULT,
                },
            });
            break;

        case SEND_TRANSITION_MEASUREMENT_DEFAULT_SUCCESS:
            dispatch(setTransitionMeasurementDefault(payload.data));
            break;

        case CLEAR_TRANSITION_MEASUREMENT_DEFAULT:
            dispatch(statusIdle(TRANSITION_MEASUREMENT_DEFAULT));
            dispatch(restoreTransitionMeasurementDefaultInitialState());
            break;

        case CREATE_TRANSITION_MEASUREMENT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    POST_TRANSITION_MEASUREMENT_REQUEST,
                    POST_TRANSITION_MEASUREMENT_SUCCESS,
                    POST_TRANSITION_MEASUREMENT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.transition.measurements(profileId),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_MEASUREMENT },
            });
            break;

        case POST_TRANSITION_MEASUREMENT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            refetchMeasurements();
            refetchGallery();
            refetchMeasurementCount();
            break;

        case POST_TRANSITION_MEASUREMENT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case UPDATE_TRANSITION_MEASUREMENT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    PATCH_TRANSITION_MEASUREMENT_REQUEST,
                    PATCH_TRANSITION_MEASUREMENT_SUCCESS,
                    PATCH_TRANSITION_MEASUREMENT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'patch',
                        url: config.endpoints.transition.measurementsUpdate(
                            profileId,
                            payload.measurementId
                        ),
                        data: payload.values,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: { ...meta, entity: TRANSITION_MEASUREMENT },
            });
            break;

        case PATCH_TRANSITION_MEASUREMENT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (meta.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction?.meta?.modalId));
            }
            refetchMeasurements();
            refetchGallery();
            break;

        case PATCH_TRANSITION_MEASUREMENT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case GET_TRANSITION_GALLERY:
            dispatch({
                types: [
                    SEND_TRANSITION_GALLERY_REQUEST,
                    SEND_TRANSITION_GALLERY_SUCCESS,
                    SEND_TRANSITION_GALLERY_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.measurements(profileId),
                        params: payload,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_GALLERY,
                },
            });
            break;

        case SEND_TRANSITION_GALLERY_SUCCESS:
            dispatch(setTransitionGallery(payload.data));
            break;

        case CLEAR_TRANSITION_GALLERY:
            dispatch(statusIdle(TRANSITION_GALLERY));
            dispatch(restoreTransitionGalleryInitialState());
            break;

        case GET_TRANSITION_MEASUREMENT_COUNT:
            dispatch({
                types: [
                    SEND_TRANSITION_MEASUREMENT_COUNT_REQUEST,
                    SEND_TRANSITION_MEASUREMENT_COUNT_SUCCESS,
                    SEND_TRANSITION_MEASUREMENT_COUNT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.transition.measurementCount(profileId),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: TRANSITION_MEASUREMENT_COUNT,
                },
            });
            break;

        case SEND_TRANSITION_MEASUREMENT_COUNT_SUCCESS:
            dispatch(setTransitionMeasurementCount(payload.data));
            break;

        case CLEAR_TRANSITION_MEASUREMENT_COUNT:
            dispatch(statusIdle(TRANSITION_MEASUREMENT_COUNT));
            dispatch(restoreTransitionMeasurementCountInitialState());
            break;

        case CLEAR_TRANSITION:
            dispatch(statusIdle(TRANSITION_DAILY_REPORTS));
            dispatch(statusIdle(TRANSITION_DAILY_REPORT));
            dispatch(restoreTransitionInitialState());
            break;

        default:
            break;
    }
};

export default transitionMiddleware;
