import './src/style/global-style.scss';
import './static/ck-edytor-content.css';

export { default as wrapRootElement } from './src/redux/store';

export const shouldUpdateScroll = ({ pathname, prevRouterProps, routerProps }) => {
    const prevPathname = prevRouterProps?.location.pathname;
    const preventScroll = routerProps.location.state?.preventScroll;
    const { location } = routerProps;
    console.log({ hash: location.hash });
    if (location.hash) {
        const elementId = location.hash.substring(1);
        document.getElementById(`${elementId}`)?.scrollIntoView();
        return false;
    }
    return !(prevPathname === pathname || preventScroll);
};
