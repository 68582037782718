import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import {
    wrapper,
    infiniteList,
    buttonWrapper,
} from './personal-training-previous-list.module.scss';
import { mainGrid } from '../../../../../style/grid.module.scss';
import { config } from '../../../../../config';
import { getAbsolutePath } from '../../../../../routes';
import {
    PERSONAL_TRAINING_LIST,
    types,
} from '../../../../../redux/personal-training/personal-training.actions';
import { selectLoaderByEntity } from '../../../../../redux/ui/ui.selectors';
import {
    selectArchivedTrainingsForList,
    selectPersonalTrainingsPagination,
} from '../../../../../redux/personal-training/personal-training.selectors';

import PersonalTrainingItemCard from '../../item-card/personal-training-item-card';
import InfinityList from '../../../../molecules/list/infinity-list';
import Button from '../../../../atoms/button';

const { apiStatusMap } = config;

const PersonalTrainingPreviousList = () => {
    const dispatch = useDispatch();

    const { list, loading, pagination } = useSelector((state) => {
        return {
            list: selectArchivedTrainingsForList(state),
            pagination: selectPersonalTrainingsPagination(state),
            loading: selectLoaderByEntity(state, PERSONAL_TRAINING_LIST) === apiStatusMap.loading,
        };
    });

    const getNextPage = () => {
        dispatch({ type: types.GET_TRAININGS });
    };

    return (
        <>
            <div className={`${wrapper} ${mainGrid}`}>
                <InfinityList
                    loading={loading}
                    className={infiniteList}
                    targetPath={getAbsolutePath('APP_MY_ACCOUNT_PERSONAL_TRAINING_PREVIOUS_LIST')}
                    ItemComponent={PersonalTrainingItemCard}
                    callbackFunction={getNextPage}
                    items={list}
                    isDisabled={pagination?.pageCount < pagination?.nextPageToFetch}
                />
            </div>
            <div className={buttonWrapper}>
                <Button
                    onClick={() =>
                        navigate(getAbsolutePath('APP_MY_ACCOUNT_PERSONAL_TRAINING_CURRENT'))
                    }
                    color={'yellow'}
                >
                    WRÓC DO AKTUALNEGO TRENINGU
                </Button>
            </div>
        </>
    );
};

export default PersonalTrainingPreviousList;
