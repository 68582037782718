import { selectLoaderByEntity } from '../ui/ui.selectors';
import {
    TRANSITION_DAILY_REPORT,
    TRANSITION_DAILY_REPORTS,
    TRANSITION_WEEKLY_REPORTS,
    TRANSITION_WEEKLY_REPORT,
    TRANSITION_REPORTS_HISTORY,
    TRANSITION_SCORES,
    TRANSITION_MEASUREMENTS,
    TRANSITION_MEASUREMENT_DEFAULT,
    TRANSITION_GALLERY,
    TRANSITION_MEASUREMENT_COUNT,
} from './transition.actions';

export function selectTransitionDailyReports(state) {
    return state.transition.dailyReports.items;
}

export function selectTransitionDailyReportsStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_DAILY_REPORTS);
}

export function selectTransitionDailyReportStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_DAILY_REPORT);
}

export function selectTransitionWeeklyReports(state) {
    return state.transition.weeklyReports;
}

export function selectTransitionWeeklyReport(state) {
    return state.transition.weeklyReport;
}

export function selectTransitionWeeklyReportsStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_WEEKLY_REPORTS);
}

export function selectTransitionWeeklyReportStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_WEEKLY_REPORT);
}

export function selectTransitionReportsHistory(state) {
    return state.transition.reportsHistory;
}

export function selectTransitionReportsHistoryStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_REPORTS_HISTORY);
}

export function selectTransitionScores(state) {
    return state.transition.scores;
}

export function selectTransitionScoresStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_SCORES);
}

export function selectTransitionMeasurements(state) {
    return state.transition.measurements;
}

export function selectTransitionMeasurementsStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_MEASUREMENTS);
}

export function selectTransitionMeasurementDefault(state) {
    return state.transition.defaultMeasurement;
}

export function selectTransitionMeasurementDefaultStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_MEASUREMENT_DEFAULT);
}

export function selectTransitionGallery(state) {
    return state.transition.gallery;
}

export function selectTransitionGalleryStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_GALLERY);
}

export function selectTransitionDailyReport(state) {
    return state.transition.dailyReport;
}

export function selectTransitionMeasurementCount(state) {
    return state.transition.measurementCount;
}

export function selectTransitionMeasurementCountStatus(state) {
    return selectLoaderByEntity(state, TRANSITION_MEASUREMENT_COUNT);
}

export function selectTransitionPlannerComments(state) {
    return state.transition.plannerComments;
}
